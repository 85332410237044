import { Typography } from "@mui/material";
import React from "react";
import { Translator } from "../lang";

const PageTitle = ({ textKey, ...props }) => {
  return (
    <Typography
      variant="h6"
      component="h2"
      sx={{
        textAlign: "center",
        pb: 4,
        fontSize: "2rem",
        fontWeight: "bold",
        position: "relative",

        "&:after": {
          content: '""',
          position: "absolute",
          width: "8em",
          height: "3px",
          backgroundColor: "var(--primary-color)",
          bottom: "1em",
          left: "50%",
          transform: "translateX(-50%)",
          opacity: ".5",
          borderRadius: "6px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
        "&:before": {
          content: '""',
          position: "absolute",
          width: "3em",
          height: "3px",
          backgroundColor: "var(--primary-color)",
          bottom: "1em",
          left: "50%",
          transform: "translateX(-50%)",
          opacity: "1",
          borderRadius: "6px",
        },
      }}
    >
      <Translator textKey={textKey} />
    </Typography>
  );
};

export default PageTitle;
