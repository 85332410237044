import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { useMutate } from "../hooks";
import { Typography } from "@mui/material";
import { setUser } from "../slices/employSlice";
import { useLocalization } from "../lang";

export default function LanguageMenu() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const languages = useSelector((state) => state.languages);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { direction } = useLocalization();
  const open = Boolean(anchorEl);

  const { mutate } = useMutate("/employer", {
    action: "put",
    onSuccess: (res) => {
      dispatch(setUser({ authenticated: true, ...res.data?.data[0] }));
      handleClose();
    },
    enabled: false,
  });

  const handleChangeLanguage = (locale_code) => {
    const updatedUser = {
      company_name: user.company_name,
      locale_code,
      name: user.name,
      mobile: user.mobile,
      full_address: user.full_address,
      city_id: user?.city?.id,
      contact_email: user.contact_email,
      longitude: user.longitude,
      latitude: user.latitude,
      facebook: user.facebook,
      linkedin: user.linkedin,
      employer_field_id: user.employer_field?.id,
    };

    mutate(updatedUser);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ width: "2.3em", height: "2.3em", backgroundColor: "#fff" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Typography
              sx={{
                padding: "0 .8em",
                color: "#000",
              }}
            >
              {user.locale_code?.toUpperCase()}
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: direction === "rtl" ? 14 : "none",
              right: direction === "ltr" ? 14 : "none",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((l, index, arr) => (
          <>
            <MenuItem key={l.id} onClick={() => handleChangeLanguage(l.code)}>
              {l.name}
            </MenuItem>
            {arr.length !== ++index ? <Divider /> : null}
          </>
        ))}
      </Menu>
    </Box>
  );
}
