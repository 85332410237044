import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  total: 0,
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    clearJobs: () => {
      return initialState;
    },

    refreshAndSetJobs: (state, action) => {
      return { ...action.payload };
    },

    mergeJobs: (state, action) => {
      return { data: action.payload.data, total: action.payload.total };
    },

    pushJob: (state, action) => {
      return {
        data: [...state.data, action.payload],
        total: state.total + 1,
      };
    },
  },
});

export const { refreshAndSetJobs, mergeJobs, clearJobs, pushJob } =
  jobsSlice.actions;
export default jobsSlice.reducer;
