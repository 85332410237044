//import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import useAxios from "./useAxios";

/**
 * Hook to fetch data from given url and handle fetch request life cycle.
 */
const useFetchMany = (urls, options = {}) => {
  const {
    initialResponses = null,
    onSuccess,
    onError,
    enabled = true,
  } = options;
  const axios = useAxios();
  const [isFetching, setIsFetching] = useState(enabled);
  const [isEnabled, setIsEnabled] = useState(enabled);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [responses, setResponses] = useState(initialResponses);

  /**
   * function use to fetch data.
   * @access private
   */
  const fetchData = useCallback(() => {
    setIsFetching(true);
    // create fetch promises for all api's
    const promises = urls.map((url) => axios.get(url));
    Promise.all(promises)
      .then((_responses) => {
        setResponses(_responses);
        setIsSuccess(true);
        // check if onSuccess function exists, then execute it and pass response to it
        if (onSuccess) {
          onSuccess(_responses);
        }
      })
      .catch((error) => {
        setIsError(true);
        // check if onError function exists, then execute it and pass error to it
        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  /**
   * Use to make fetch conditionaly, make sure to make enabled option to false when use conditional fetch request.
   */
  const enable = useCallback(() => {
    setIsEnabled(true);
  }, []);

  /**
   * Refetch data.
   */
  const refetch = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isEnabled) {
      fetchData();
    }
  }, [isEnabled]);

  return { responses, isFetching, isError, isSuccess, enable, refetch };
};

export default useFetchMany;
