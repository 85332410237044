import React from "react";
import "./style.css"

const Spinner = ({
  size = "sm",
  color = "white",
  className = "",
  ...props
}) => {
  return (
    <div className={`lds-ring ${size} ${color} ${className}`} {...props}>
      <div></div>
      <div ></div>
      <div ></div>
      <div ></div>
    </div>
  );
};

export default Spinner;
