import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField, FormControl } from "@mui/material";
import { useLocalization } from "../lang";

const ComboBox = ({
  defaultValue,
  options = [],
  labelKey,
  error,
  ...props
}) => {
  const { translator } = useLocalization();

  const dv = useMemo(() => {
    const option = options.find((o) => o.id == defaultValue);
    const d = option ? option.label : undefined;
    return d;
  }, [options, defaultValue]);

  return (
    <Autocomplete
      options={options}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={!dv ? translator(labelKey) : undefined}
          size="small"
          helperText={error || undefined}
          error={!!error}
          placeholder={dv}
        />
      )}
      {...props}
    />
  );
};

export default ComboBox;
