import React, { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";

const FileInputButton = ({ sx, handleFileChange }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    handleFileChange(e);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        width: "fit-content",
        borderRadius: "50%",
        ...sx,
      }}
    >
      <input
        type="file"
        ref={inputRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <button
        onClick={handleButtonClick}
        style={{
          background: "var(--primary-color)",
          hover: "none",
          width: "4em",
          height: "4em",
          borderRadius: "50%",
          outline: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "4px solid #fff",
        }}
      >
        <AddIcon
          sx={{
            color: "#fff",
            width: "2rem",
            height: "2rem",
          }}
        />
      </button>
    </Box>
  );
};

export default FileInputButton;
