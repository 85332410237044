import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const formatedCitiesSlice = createSlice({
  name: "formatedCities",
  initialState,
  reducers: {
    setFormatedCities: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setFormatedCities } = formatedCitiesSlice.actions;
export default formatedCitiesSlice.reducer;
