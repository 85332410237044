import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const langaugesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      return [ ...action.payload];
    },
  },
});

export const { setLanguages } = langaugesSlice.actions;
export default langaugesSlice.reducer;
