import Axios from "axios";
import { API_URL } from "../config";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { redirect, useLocation } from "react-router-dom";

const useAxios = () => {
  const user = useSelector((state) => state.user);
  const token = Cookies.get("token");
  const { pathname } = useLocation();

  // * Create axios instance
  const axios = Axios.create({
    baseURL: API_URL,
    headers: {
      common: {
        Authorization: "Bearer " + (user.token || token),
      },
    },
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // * if user is not authenticated and current page is not login page, then redirect user to login page
      if (
        (error?.status === 401 || error.response?.status === 401) &&
        pathname !== "/verify"
      ) {
        redirect("/login");
        return;
      }

      return Promise.reject(error);
    }
  );

  return axios;
};

export default useAxios;
