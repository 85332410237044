import React from "react";
import { useFetch } from "../hooks";
import { useDispatch } from "react-redux";
import { setUser } from "../slices/employSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "./";

const GetCurrenUser = ({ children }) => {
  const dispatch = useDispatch();
  const pathname = useLocation();
  const navigate = useNavigate();
  const navigateTo = pathname !== ("/login" || "/register") ? pathname : "/";

  const { isFetching } = useFetch(`/user/info`, {
    onSuccess: (res) => {
      dispatch(setUser({ ...res.data?.data[0], authenticated: true }));
      navigate(navigateTo);
    },
  });

  if (isFetching) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default GetCurrenUser;
