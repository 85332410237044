import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "./useFetch";
import { setCities } from "../slices/citiesSlice";

const useFetchCities = ({ provinceId } = {}) => {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.cities);

  const { refetch, ...rest } = useFetch("/employer/province", {
    onSuccess: (res) => {
      dispatch(setCities(res?.data?.data[0]?.city));
    },
    enabled: false,
  });

  // fetch cities when province_id change
  useEffect(() => {
    if (provinceId) {
      refetch(`/${provinceId}`);
    }
  }, [provinceId]);

  return { cities, refetch, ...rest };
};

export default useFetchCities;
