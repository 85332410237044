import {
  Popup,
  Marker as MK,
  CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon_location from "../../assets/images/pin.png";

const CustomIcon = new L.Icon({
  iconUrl: icon_location,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const optionLive = { color: "#2980b9" };

export function LocationMarker({ pos, onMove }) {
  return (
    <>
      <MK
        position={pos}
        draggable
        autoPan
        icon={CustomIcon}
        eventHandlers={{
          moveend: (event) => {
            onMove([
              event.target.getLatLng().lat,
              event.target.getLatLng().lng,
            ]);
          },
        }}
      />
      <CircleMarker center={pos} pathOptions={optionLive} radius={10}>
        <Popup>Popup in CircleMarker</Popup>
      </CircleMarker>
    </>
  );
}
