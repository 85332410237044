import useFetch from "./useFetch";
import { useDispatch, useSelector } from "react-redux";
import { setProvinces } from "../slices/provincesSlice";
import { useEffect } from "react";

const useFetchProvince = () => {
  const dispatch = useDispatch();
  const provinces = useSelector((state) => state.provinces);

  const { enable, ...rest } = useFetch("/employer/province", {
    enabled: false,
    onSuccess: (res) => {
      dispatch(setProvinces(res?.data?.data));
    },
  });

  useEffect(() => {
    if (!provinces.length) {
      enable();
    }
  }, []);

  return {provinces, ...rest};
};

export default useFetchProvince;
