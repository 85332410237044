import { useDispatch, useSelector } from "react-redux";
import useFetch from "./useFetch";
import { setApplicantStages } from "../slices/applicantStagesSlice";
import { useEffect } from "react";

const useFetchApllicantStages = () => {
  const dispatch = useDispatch();
  const stages = useSelector((state) => state.applicantStages);

  // fetch applicant stages
  const { enable, ...rest } = useFetch(`/employer/applicant-stage`, {
    enabled: false,
    onSuccess: (res) => {
      const { data } = res.data;
      dispatch(setApplicantStages(data));
    },
  });

  useEffect(() => {
    if (!stages.length) {
      enable();
    }
  }, []);

  return { stages, ...rest };
};

export default useFetchApllicantStages;
