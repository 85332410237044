import React from "react";
import img_logo from "../../assets/images/logo.png";
import "./style.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className="loader-image">
        <img style={{ width: 70 }} src={img_logo} alt="logo" />
      </div>
    </div>
  );
};

export default Loader;
