import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { IsRegister, PrivateRoute } from "./components";
import { lazyImport } from "./utils";

const { Login } = lazyImport(() => import("./pages/Login/index"), "Login");
const { Register } = lazyImport(
  () => import("./pages/Register/index"),
  "Register"
);
const { Home } = lazyImport(() => import("./pages/Home/index"), "Home");
const { ForgotPassword } = lazyImport(
  () => import("./pages/ForgotPassword/index"),
  "ForgotPassword"
);
const { ResetPassword } = lazyImport(
  () => import("./pages/ResetPassword/index"),
  "ResetPassword"
);

const { DeleteAccount } = lazyImport(
  () => import("./pages/deleteAccount/index"),
  "DeleteAccount"
);

const { DeleteVerify } = lazyImport(
  () => import("./pages/deleteAccount/index"),
  "DeleteVerify"
);

const { VerifyEmail } = lazyImport(
  () => import("./pages/VerifyEmail/index"),
  "VerifyEmail"
);

const { Profile } = lazyImport(
  () => import("./pages/Profile/index"),
  "Profile"
);

const { CompleteRegister } = lazyImport(
  () => import("./pages/CompleteRegister/index"),
  "CompleteRegister"
);

const { Jobs } = lazyImport(() => import("./pages/Jobs/index"), "Jobs");
const { AddJob } = lazyImport(() => import("./pages/Jobs/index"), "AddJob");
const { SeekerProfile } = lazyImport(
  () => import("./pages/Seeker/index"),
  "SeekerProfile"
);

const { JobDetailes } = lazyImport(
  () => import("./pages/Jobs/index"),
  "JobDetailes"
);

const { Notifications } = lazyImport(
  () => import("./pages/Notifications/index"),
  "Notifications"
);

const RouterApp = () => {
  return (
    <Routes>
      {/* ! Private Routes */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <IsRegister>
              <Navigate to="/profile" />
            </IsRegister>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <IsRegister>
              <Profile />
            </IsRegister>
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs"
        element={
          <PrivateRoute>
            <IsRegister>
              <Jobs />
            </IsRegister>
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/add"
        element={
          <PrivateRoute>
            <IsRegister>
              <AddJob />
            </IsRegister>
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/:id"
        element={
          <PrivateRoute>
            <IsRegister>
              <JobDetailes />
            </IsRegister>
          </PrivateRoute>
        }
      />
      <Route
        path="/completeRegister"
        element={
          <PrivateRoute>
            <CompleteRegister />
          </PrivateRoute>
        }
      />
      <Route
        path="/seeker/:id"
        element={
          <PrivateRoute>
            <SeekerProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />

      {/* ! Public Routes */}
      <>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/delete-verify" element={<DeleteVerify />} />
      </>
    </Routes>
  );
};

export default RouterApp;
