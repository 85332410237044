import store from "../store";

/**
 * Return the current state from reducx store.
 * @param {*} cb callback function with state.
 */
export function getState(cb) {
  const state = store.getState();
  return cb(state);
}
