import { createSlice } from "@reduxjs/toolkit";
import { updateOne } from "../utils";

const initialState = {
  data: [],
  total: 0,
};

const applicantsSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    clearApplicants: () => {
      return initialState;
    },

    setApplicants: (state, action) => {
      return { ...action.payload };
    },

    updateSeekerStage: (state, action) => {
      const newData = updateOne(
        state.data,
        (item) => item.id === action.payload.applicantId,
        (item) => ({ ...item, applicant_stage: action.payload.newStage })
      );

      return { ...state, data: newData };
    },
  },
});

export const { clearApplicants, setApplicants, updateSeekerStage } =
  applicantsSlice.actions;
export default applicantsSlice.reducer;
