import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetched: false,
  educationsDegree: [],
  jobsTitle: [],
  workTypes: [],
  cities: [],
};

const jobDataSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    setJobData: (state, action) => {
      return { isFetched: true, ...action.payload };
    },
  },
});

export const { setJobData } = jobDataSlice.actions;
export default jobDataSlice.reducer;
