import React, { useEffect } from "react";
import { useFetch } from "../hooks";
import { useLocalization, getLang } from "../lang";
import { useDispatch, useSelector } from "react-redux";
import { setLanguages } from "../slices/languagesSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Loader from "./Loader";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const LanguageSetting = ({ children }) => {
  console.log("language setting");
  const dispatch = useDispatch();
  const { locale_code } = useSelector((state) => state.user);
  const { setCode, setLanguge, direction } = useLocalization();

  const { isFetching } = useFetch("/employer/language", {
    onSuccess: (res) => {
      const languages = res.data?.data;
      // return only ar, en language
      const filteredLanguage = languages.filter(
        (lang) => lang.code === "en" || lang.code === "ar"
      );

      // set language to redux store
      dispatch(setLanguages(filteredLanguage));
    },
  });

  useEffect(() => {
    if (locale_code) {
      // set language code to localization provider
      setCode(locale_code);
      document.querySelector("html").setAttribute("lang", locale_code);
      // set language object to localization provider
      setLanguge(getLang(locale_code));
    }
  }, [locale_code]);

  if (isFetching) {
    return <Loader />;
  }

  // * if direction is rtl apply rtl theme to mui
  if (direction === "rtl") {
    return (
      <ThemeProvider theme={theme}>
        <CacheProvider value={cacheRtl}>
          <div dir={direction}>{children}</div>
        </CacheProvider>{" "}
        <ToastContainer position={"top-left"} />
      </ThemeProvider>
    );
  }

  return (
    <div dir={direction}>
      {children} <ToastContainer />
    </div>
  );
};

export default LanguageSetting;
