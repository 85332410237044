import React from "react";
import { getLang } from "./lang";
import { useLocalization } from "./LocalizationContext";

const Translator = ({ textKey }) => {
  const { code } = useLocalization();
  const language = getLang(code);

  return <>{language[textKey] || null}</>;
};

export default Translator;
