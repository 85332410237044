import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  email: null,
  id: null,
  is_register: false,
  locale_code: null,
  mobile: null,
  name: null,
  profile_pic: null,
  type: null,
  token: null,
  company_name: null,
  contact_email: null,
  full_address: null,
  social_type: null,
  facebook: null,
  linkedin: null,
  website: null,
  latitude: null,
  longitude: null,
  city: {},
  province: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    handleLogOut: () => {
      return initialState;
    },
  },
});

export const { setUser, handleLogOut } = userSlice.actions;
export default userSlice.reducer;
