import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const provincesSlice = createSlice({
  name: "provinces",
  initialState,
  reducers: {
    setProvinces: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setProvinces } = provincesSlice.actions;
export default provincesSlice.reducer;
