import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/employSlice";
import provincesReducer from "../slices/provincesSlice";
import citiesReducer from "../slices/citiesSlice";
import languagesReducer from "../slices/languagesSlice";
import jobsReducer from "../slices/jobsSlice";
import jobDataReducer from "../slices/jobDataSlice";
import applicantsReducer from "../slices/applicantsSlice";
import applicantStagesReducer from "../slices/applicantStagesSlice";
import notificationsReducer from "../slices/notificationsSlice";
import formatedCitiesReducer from "../slices/formatedCitiesSlice";
import employerFieldReducer from "../slices/EmployerFieldSlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    provinces: provincesReducer,
    cities: citiesReducer,
    languages: languagesReducer,
    jobs: jobsReducer,
    jobData: jobDataReducer,
    applicants: applicantsReducer,
    applicantStages: applicantStagesReducer,
    notifications: notificationsReducer,
    formatedCities: formatedCitiesReducer,
    employer_field: employerFieldReducer,
  },
});

export default store;
