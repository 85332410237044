import React from "react";
import { Translator } from "../lang";
import { Controller, useFormState } from "react-hook-form";
import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  Select as MuiSelect,
} from "@mui/material";

const Select = ({
  name,
  control,
  Icon,
  defaultValue,
  children,
  title,
  variant = "standard",
  disabled,
  sx,
  style,
  className = "",
  ...props
}) => {
  const { errors } = useFormState({ control, name });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { disabled: _disabled, ...field } }) => (
        <FormControl
          variant={variant}
          disabled={disabled}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            backgroundColor: "transparent",
            ...sx,
          }}
          style={style}
          className={className}
        >
          {Icon && (
            <Icon
              sx={{
                color: "var(--primary-color)",
                marginInlineEnd: 1,
                my: 0.5,
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MuiSelect
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: "100%" }}
              error={errors[name]?.message ? true : false}
              defaultValue={defaultValue}
              {...props}
              {...field}
            >
              <MenuItem value={defaultValue || field.value} disabled>
                <em>
                  <Translator textKey={title} />
                </em>
              </MenuItem>
              {children}
            </MuiSelect>
            {errors[name]?.message && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  marginTop: ".2em",
                }}
              >
                {errors[name]?.message}
              </Typography>
            )}
          </Box>
        </FormControl>
      )}
    />
  );
};

export default Select;
