/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

const useQueryParams = (defaultInit) => {
  const [urlParams, setUrlParams] = useSearchParams(defaultInit);
  console.log("urlParams", urlParams.toString());
  /**
   * Set param to search parameters.
   * @param {string} name key name for param.
   * @param {string} value value for param.
   * @return void
   */
  const setParam = useDebouncedCallback((name, value) => {
    if (value === "") {
      urlParams.delete(name);
    } else urlParams.set(name, value);
    console.log("name, value", name, value);
    setUrlParams(urlParams, { replace: true });
  }, 1000);

  /**
   * Set params to search parameters.
   * @param {object} params object that has key, value pairs for params and its values.
   */
  const setParamsObj = (params) => {
    setUrlParams(params, { replace: true });
  };

  /**
   * Get value for spicific param by pass its name.
   * @param {string} name name for param that want to retrieve its value.
   * @returns
   */
  const getParam = (name) => {
    return urlParams.get(name);
  };

  /**
   * delete all search params.
   */
  const clear = () => {
    setUrlParams({}, { replace: true });
  };

  return {
    params: urlParams,
    setParam,
    getParam,
    setParams: setParamsObj,
    clear,
  };
};

export default useQueryParams;
