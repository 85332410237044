import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "./Slidbar";
import { useFetch } from "../hooks";
import { setLanguages } from "../slices/languagesSlice";
import Loader from "./Loader";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages);

  const { isFetching, refetch } = useFetch("/employer/language", {
    enabled: false,
    onSuccess: (res) => {
      const languages = res.data?.data;
      // return only ar, en language
      const filteredLanguage = languages.filter(
        (lang) => lang.code === "en" || lang.code === "ar"
      );

      // set language to redux store
      dispatch(setLanguages(filteredLanguage));
    },
  });

  useEffect(() => {
    if (!languages.length) {
      refetch();
    }
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  return <Slider>{children}</Slider>;
};

export default MainLayout;
