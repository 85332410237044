import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";
import { Controller } from "react-hook-form";

const Switch = ({ name, control, defaultChecked, label, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<MuiSwitch defaultChecked={defaultChecked} {...field} />}
          label={label}
          {...props}
        />
      )}
    />
  );
};

export default Switch;
