import { Box, Typography } from "@mui/material";
import React from "react";

const SectionTitle = ({ children, Icon, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...sx,
      }}
      {...props}
    >
      {Icon && (
        <Icon
          sx={{
            fill: "var(--primary-color)",
          }}
        />
      )}
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "var(--primary-color)",
          fontSize: "1.5rem",
          fontWeight: "bold",
          paddingInlineStart: ".2em",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default SectionTitle;
