import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const employerFieldSlice = createSlice({
  name: "employer_field",
  initialState,
  reducers: {
    setEmployerField: (state, action) => {
      console.log(action.payload);
      return [...action.payload];
    },
  },
});

export const { setEmployerField } = employerFieldSlice.actions;
export default employerFieldSlice.reducer;
