import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useQueryParams } from "../hooks";
import { Translator } from "../lang";

const borders_shadow = {
  borderRadius: "12px",
  boxShadow:
    "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
};

function NoRowsOverlay({ noRowsMessage }) {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "2em",
      }}
    >
      <Typography
        as="h2"
        sx={{
          fontSize: "1.4rem",
          color: "var(--primary-color)",
          borderBottom: "2px solid var(--primary-color)",
        }}
      >
        <Translator textKey={noRowsMessage} />!
      </Typography>
    </Stack>
  );
}

const Grid = React.memo(
  ({ data, columns, totalRows, isLoading, noRowsMessage, ...props }) => {
    const { setParams, getParam } = useQueryParams();
    const [rowCountState, setRowCountState] = useState(data.length || 0);
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 10,
    });

    useEffect(() => {
      setRowCountState((prevRowCountState) =>
        totalRows !== undefined ? totalRows : prevRowCountState
      );
    }, [totalRows, setRowCountState]);

    return (
      <Card
        sx={{
          margin: 0,
          padding: 0,
          ...borders_shadow,
        }}
        className="card-data-grid"
      >
        <CardContent>
          {!isLoading ? (
            <DataGrid
              sx={{
                minHeight: "80vh",
                width: "100%",
                margin: 0,
                padding: 0,
                "& .employer-grid--header": {
                  backgroundColor: "#1a8b9d",
                  color: "#fff",
                },
                ...borders_shadow,
              }}
              autoHeight
              virtualization
              rowHeight={55}
              rows={data}
              columns={columns}
              rowCount={rowCountState}
              loading={isLoading}
              pageSizeOptions={[10]}
              paginationMode="server"
              initialState={{
                pagination: { paginationModel },
              }}
              paginationModel={paginationModel}
              onPaginationModelChange={(p) => {
                setPaginationModel(p);
                setParams({
                  page: p.page,
                  take: p.pageSize,
                  skip: p.page * p.pageSize,
                });
              }}
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: () => (
                  <NoRowsOverlay noRowsMessage={noRowsMessage} />
                ),
              }}
              {...props}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "80vh",
              }}
            >
              <LinearProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }
);

export default Grid;
