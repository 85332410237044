import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const applicantStagesSlice = createSlice({
  name: "applicantStages",
  initialState,
  reducers: {
    setApplicantStages: (state, action) => {
      return action.payload;
    },
  },
});

export const { setApplicantStages } = applicantStagesSlice.actions;
export default applicantStagesSlice.reducer;
