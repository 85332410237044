import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { CurrentLocationButton } from "./CurrentLocationButton";
import { LocationMarker } from "./Marker";
import "leaflet/dist/leaflet.css";
import "./style.css";

export const Map = ({ handleChange, init, className = "", style }) => {
  const [markerPos, setMarkerPos] = useState(
    init || [33.262751244441105, 44.41386222839356]
  );
  const mapRef = useRef(null);
  const liveLocationMarkerRef = useRef();

  const handleShowMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          // setPositionLive([latitude, longitude]);
          setMarkerPos([latitude, longitude]);
          if (mapRef.current) {
            mapRef.current.setView([latitude, longitude], 16);
          }

          // Update the position of the live location marker
          if (liveLocationMarkerRef.current) {
            liveLocationMarkerRef.current.setLatLng([latitude, longitude]);
          }
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    if (!init) {
      handleShowMyLocation();
    }
  }, []);

  useEffect(() => {
    if (handleChange) {
      handleChange(markerPos);
    }
  }, [markerPos]);

  return (
    <div className={`map-container ${className}`} style={style}>
      <MapContainer
        center={markerPos}
        zoom={13}
        style={style}
        className={`map ${className}`}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="© OpenStreetMap contributors"
        />
        <LocationMarker onMove={setMarkerPos} pos={markerPos} />
      </MapContainer>
      <CurrentLocationButton handleShowMyLocation={handleShowMyLocation} />
    </div>
  );
};
