import React, { createContext, useCallback, useContext, useState } from "react";

const LocalizationContext = createContext({
  // * => property that return current code language like en, ar etc.
  code: "",
  // * => property that return current direction rtl or ltr.
  direction: "",
  // * => property that return current language object in key value pair.
  language: {},
  // * => function that take key and return the translate for the key.
  translator: (key) => {},
  // * => function that used to set code
  setCode: (code) => {},
  // * => function that used to set language object
  setLanguge: (language) => {},
});

export const LocalizationProvider = ({
  langCode = "en",
  defaultLanguage = {},
  children,
}) => {
  const [code, setCode] = useState(langCode);
  const [language, setLanguge] = useState(defaultLanguage);
  const direction = code === "en" ? "ltr" : "rtl";

  const translator = useCallback(
    (key) => {
      return language[key];
    },
    [language]
  );

  return (
    <LocalizationContext.Provider
      value={{ code, direction, language, setCode, setLanguge, translator }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);

  if (!context) {
    throw new Error(
      "useLocalization hook must be used inside LocalizationProvider."
    );
  }

  return context;
};
