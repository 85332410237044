import { getState } from "./getState";
import { getBrowserLanguage } from "./getBrowserLanguage";

/**
 * Get the current language code from redux if it is existing,
 * otherwisenget browoser default language.
 */
export function getLangCode() {
  const locale_code = getState((state) => state?.user?.locale_code);
  const code = locale_code || getBrowserLanguage();
  return code;
}
